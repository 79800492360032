<template>
  <div>
    <BaseLoading style="min-height: 400px; height: 90vh;" v-if="loading"></BaseLoading>

    <div v-else>
      <!-- <BaseHeader :title="'My Writers'"></BaseHeader> -->

      <div class="card mt-2 border-0  bg-white shadow">
            <div class="card-body text-right d-flex" style="">
              <!-- <form>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Start typing to search">
                  <span class="input-group-append">
                    <button class="btn btn-success" type="search">Search</button>
                  </span>
                </div>
              </form> -->
              <h3>My Writers</h3>
              <!-- <router-link :to="{ name: 'PlaceOrder' }" class="btn btn-primary ml-auto">Order Now</router-link> -->
            </div>

            

            <div class="table-responsive text-nowrap">
              <!--Table-->
              <table class="table table-striped">

                <!--Table head-->
                <thead>
                  <tr>
                    <th class="text-left" >#</th>
                    <th class="text-left">ID</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Ratings</th>
                    <th class="text-left">Completed Orders</th>
                    <th class="text-left">Action</th>
                  </tr>
                </thead>
                <!--Table head-->

                <!--Table body-->
                <tbody v-if="writers.length > 0" >
                  <tr v-for="(writer, index) in writers" :key="index + 'ords'">
                    <th class="text-left" scope="row">{{ index+1 }}</th>
                    <td class="text-left"> {{ writer.id || "" }}</td>
                    <td class="text-left">{{ writer.first_name || "" }}</td>
                    <td>
                      <p class="is-rating-stars- d-flex mb-1">
                          <span v-for="(i, index) in 5" :key="index + 'rating'">
                              <span class="text-warning icon" :class="writer.rating.average_rating >= index + 1 ? 'icon-star-full2' : 'icon-star-empty3'"></span>
                          </span> 
                          <strong class="ml-2 ">{{ writer.rating.average_rating || 0 }}</strong>
                      </p>
                    </td>
                    <td class="text-left">{{ writer.rating.completed_orders || "" }}</td>
                    <td class="text-left">
                      <a data-toggle="modal" data-target="#tipModal" class="btn btn-success text-white"><i class="icon-wallet"></i> <span class="d-none d-sm-inline">Tip</span></a>
                    </td>
                  </tr>
                  
                </tbody>
                <!--Table body-->
                <tfoot v-else>
                  <tr class="py-3" >
                    <td colspan="11"> <strong  class="custom_imageEmpty  " >No writers!</strong> </td>
                  </tr>
                </tfoot>


              </table>
              <!--Table-->
            </div>
      </div>

    </div>

    <!-- tip writer -->
    <BaseModal :title="'Tip Writer'" :modalId="'tipModal'">
      <TipWriter></TipWriter>
    </BaseModal>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TipWriter from '../../components/payments/modals/TipWriter.vue'

export default {
  name: "MyWriters",
  computed: {
    ...mapState("writers", ["writers", "loading"])
  },
  components: {
    TipWriter
  },

  mounted() {
    this.getWriters()
  },
  methods: {
    ...mapActions("writers", ["getWriters"])
  }
};
</script>
